import React from "react"
import { getNewestDate, getPromotionLink, getVariables, generatePath } from "../lib/utils"
import Layout from "../components/Layout"
import CompareBody from "../components/CompareBody"
import PageTitle from "../components/PageTitle"
import CompareSidebarSearch from "../components/CompareSidebarSearch"
import Seo from "../components/Seo/Seo"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"

export const query = graphql`
    query ($slug: [String!]) {
        datoCmsLocation(websiteUrl: {eq: "in.mattress.zone"}) {
            singleMetaTitleTemplate
            singleMetaDescriptionTemplate
            currencySymbol
            dimensionsSymbol
            weightSymbol
        }
        allDatoCmsMattress(
            filter: {
                locale: {eq: "en"}, 
                slug: {in: $slug}, 
                location: {
                    websiteUrl: {eq: "in.mattress.zone"}
                    }
                }
            ) {
            nodes {
                mattressImage {
                    gatsbyImageData(
                        imgixParams: {auto: "format", fit: "crop", h: "330", w: "660", colors: 10, mask: "corners", cornerRadius: "10,10,10,10"}
                        placeholder: BLURRED
                    )
                }
                ...FragmentMattressFields
            }
        }
        datoCmsTranslation(locale: {eq: "en"}) {
            ...FragmentTranslationFields
        }
    }`

const Mattress = ({ data, pageContext }) => {
    const variables = getVariables()

    let pageTitle = 'Mattress' //default value

    let affiliateLink = null

    let allCompareMattressesSizes = []
    data.allDatoCmsMattress.nodes.map((mattress, i) => {
        pageTitle = mattress.name + ' ' + data.datoCmsTranslation.mattress
        affiliateLink = getPromotionLink(mattress)

        let buttons = mattress.buttons
        for (let i = 0; i < buttons.length; i++) {
            let isRepeatedSize = allCompareMattressesSizes.indexOf(buttons[i].size.sizeName)
            if (isRepeatedSize === -1) {
                //not found so lets add the size
                allCompareMattressesSizes.push(buttons[i].size.sizeName)
            }
        }
        return null
    })

    let allDates = []
    allDates.push(data.allDatoCmsMattress.nodes[0].meta.updatedAt)
    allDates.push(data.allDatoCmsMattress.nodes[0].brand.meta.updatedAt)
    let newestDate = getNewestDate(allDates)

    //SEO metadata
    let metaTitle = data.datoCmsLocation.singleMetaTitleTemplate
    let metaDescription = data.datoCmsLocation.singleMetaDescriptionTemplate
    let metaImage = data.allDatoCmsMattress.nodes[0].mattressImage.gatsbyImageData.images.fallback.src
    let metaSlug = generatePath(
        [data.allDatoCmsMattress.nodes[0].slug],
        'en',
        variables.mattress,
        variables.mattress
    )

    metaTitle = metaTitle.replace('%%title%%', data.allDatoCmsMattress.nodes[0].name)
    metaTitle = metaTitle.replace('%%currentyear%%', newestDate.year)

    metaDescription = metaDescription.replace('%%title%%', data.allDatoCmsMattress.nodes[0].name)
    //metaDescription = metaDescription.replace('%%currentmonth%%', newestDate.month)

    return (
        <Layout>
            <Seo
                title={metaTitle}
                description={metaDescription}
                image={metaImage}
                pathname={metaSlug}
                article
            />
            <div className="bg-zone-1">
                <div className="container mx-auto flex flex-col lg:flex-row justify-between">
                    <main className="w-full lg:w-3/4 lg:pr-8">
                        <PageTitle
                            newestDate={newestDate}
                            title={pageTitle}
                            link={affiliateLink}
                            category={variables.mattress}
                            rel="nofollow noopener sponsored" />

                        {data.allDatoCmsMattress.nodes.map((mattress, i) => {
                            const image = getImage(mattress.mattressImage)
                            /* eslint-disable */
                            return (
                                <div className="mb-6 text-center px-2 md:px-4 xl:px-0" key={`mattress-image-item-${i}`}>
                                    {affiliateLink ? (
                                        <a
                                            href={affiliateLink}
                                            target="_blank"
                                            rel="nofollow noopener sponsored"
                                            id="AffTitleClick-img-single"
                                            title={`Here you can visit ${pageTitle}'s webpage`}
                                        >
                                            <GatsbyImage image={image} alt={`Here you can visit ${pageTitle}'s webpage`} className="custom-blend" />
                                        </a>
                                    ) : (
                                        <GatsbyImage image={image} alt={`Here you can visit ${pageTitle}'s webpage`} className="custom-blend" />
                                    )}
                                </div>)
                            /* eslint-enable */
                        })}

                        <CompareBody mattresses={data.allDatoCmsMattress.nodes} translations={data.datoCmsTranslation} location={data.datoCmsLocation} sizes={allCompareMattressesSizes} />
                    </main>
                    <CompareSidebarSearch rawSlugs={pageContext.slug} className="w-full px-2 md:px-4 xl:px-0 lg:w-1/4" />
                </div>
            </div>
        </Layout>
    )
}

export default Mattress

